import React, { useState } from 'react';
import axios from 'axios';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import CallToAction from './CallToAction';
import SuccessMessage from './SuccessMessage';
import SubscriptionInfo from './SubscriptionInfo';

const SubscribeEmail = ({
  postURL = `${process.env.GATSBY_API_URL}/jobseekers-email-subscriptions`,
  ctaHeading = '',
  ctaDescription = '',
  successHeading = '',
  successDescription = '',
  subscriptionInfo = '',
}) => {
  const [isVisibleText, setIsVisibleText] = useState(false);
  const [form] = Form.useForm();

  const handleSubmitFinish = async (values) => {
    await axios
      .post(postURL, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
      })
      .then((res) => {
        setIsVisibleText(true);
        setTimeout(() => {
          setIsVisibleText(false);
        }, 5000);
      })
      .catch((err) => console.error(err));

    form.resetFields();
  };

  return (
    <section className="email-subscription-container _section-padding">
      {isVisibleText ? (
        <SuccessMessage
          successHeading={successHeading}
          successDescription={successDescription}
        />
      ) : (
        <div className="email-subscription">
          <CallToAction
            ctaHeading={ctaHeading}
            ctaDescription={ctaDescription}
          />
          <div className="subscription-form">
            <Form form={form} onFinish={handleSubmitFinish}>
              <div className="subscription-form-items">
                <div className="subscription-form-input-container top">
                  <div className="subscription-form-input-name">
                    <span className="subscription-form-input-label">
                      First name
                    </span>
                    <Form.Item
                      className="subscription-form-input"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your first name.',
                        },
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>

                  <div className="subscription-form-input-name">
                    <span className="subscription-form-input-label">
                      Last name
                    </span>
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your last name.',
                        },
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </div>

                <div className="subscription-form-input-container bottom">
                  <div className="subscription-form-input-email">
                    <span className="subscription-form-input-label">
                      Email address
                    </span>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: 'Please input your email.' },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}
                    >
                      <Input className="form-email" />
                    </Form.Item>
                  </div>

                  <SubscriptionInfo subscriptionInfo={subscriptionInfo} />
                  <div className="subscription-form-submit-btn-container">
                    <span
                      className="subscription-form-input-label"
                      style={{ opacity: 0 }}
                    >
                      .
                    </span>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="subscription-form-submit-btn"
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </section>
  );
};

export default SubscribeEmail;
