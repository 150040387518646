import React from 'react';

const defaultCTADescription = 'When Wan PNG launches we will notify you.';
const defaultCTAHeading = (
  <>
    Be the <br /> first to know
  </>
);

const CallToAction = ({
  ctaHeading = defaultCTAHeading,
  ctaDescription = defaultCTADescription,
}) => {
  return (
    <div className="call-to-action">
      <div className="heading">{ctaHeading}</div>
      <div className="description">{ctaDescription}</div>
    </div>
  );
};

export default CallToAction;
