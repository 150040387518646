import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '@components/Layout/LayoutTemplates/EmployersLayout';
import SEO from '@components/SEO';
import SubscribeEmail from '@components/SubscribeEmail';
import CorporateSupporters from '@src/components/CorporateSupporters';
import AboutUs from '@components/AboutUs';
import HeroImage from '@modules/Home/HeroImage';
import Benefits from '@modules/Home/Benefits';
import PlatformPartners from '@components/PlatformPartners';
import OfficialNewsMediaPartners from '@components/OfficialNewsMediaPartners';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const Employers = () => {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "employer-hero-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
    }
  `);

  const subscriptionEmailProps = {
    postURL: `${process.env.GATSBY_API_URL}/employers-email-subscriptions`,
    ctaHeading: 'Stay Connected!',
    ctaDescription:
      "We'll keep you up to date for latest posts, courses, events and news.",
    successHeading: 'Thank you!',
    successDescription:
      'Thank you for subscribing to our newsletter. You should receive a confirmation email soon.',
  };

  return (
    <Layout isMobileComingSoon={false}>
      <SEO title="Employers" description="Employers" />
      <section className="employers-home-section">
        <HeroImage
          imgSrc={file}
          header={'Hiring made easy.'}
          subHeader={'Hire the best talent with Wan PNG.'}
        />

        <Benefits
          header={
            'Wan PNG provides recruitment solutions that connect you with the talent you need.'
          }
          infos={[
            {
              xlinkHref: '#talent-pool-icon',
              title: 'Increased talent pool',
              description:
                'Promote your opportunities to our national candidate database, putting the talent of a nation at your fingertips.',
            },
            {
              xlinkHref: '#quality-talent-icon',
              title: 'Quality local talent',
              description:
                'Identify and hire the best local talent by leveraging our skills and competency matching algorithms.',
            },
            {
              xlinkHref: '#community-icon',
              title: 'Unified community',
              description:
                'Be part of the community by promoting your training courses, internships, and entry-level positions to locals.',
            },
          ]}
        />

        <SubscribeEmail {...subscriptionEmailProps} />

        <PlatformPartners onSection />
        <OfficialNewsMediaPartners onSection />
        <CorporateSupporters onSection />
        <AboutUs offSection />
      </section>
    </Layout>
  );
};

export default Employers;
