import React from 'react';

const SuccessMessage = ({
  successHeading = 'Thank you!',
  successDescription = 'Thank you for subscribing to our newsletter. You should receive a confirmation email soon.',
}) => {
  return (
    <section className="success-message">
      <div className="heading">{successHeading}</div>
      <div className="description">{successDescription}</div>
    </section>
  );
};

export default SuccessMessage;
