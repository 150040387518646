import React from 'react';

const SubscriptionInfo = ({
  subscriptionInfo = 'By subscribing, you agree to receive a monthly newsletter from WanPNG.',
}) => {
  return (
    <>
      {subscriptionInfo ? (
        <div className="subscription-info-container">
          <span className="subscription-info">{subscriptionInfo}</span>
        </div>
      ) : null}
    </>
  );
};

export default SubscriptionInfo;
